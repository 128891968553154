<template>
  <div class="list">
    <navbar :title="$t('global.appointmentlist')"></navbar>
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
            ></head-menu-filter>
            <div class="row mb-2">
              <div
                class="
                  viewfilter
                  block
                  br-t-l-0 br-t-r-0
                  w-100-p
                  br-t-l-xl-0 br-t-r-xl-0
                  mt-minus-10
                "
              >
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters.name"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.name") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters.vorname"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.firstname") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters['ersteller.personName']"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.creator") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters.bezeichnung"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.designation") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        id="date-erstellt-von"
                        date-format="dd.mm.yy"
                        showIcon
                        :initial="filters.erstelldatumVon"
                        field="erstelldatumVon"
                        @updatefull="setFilterDate"
                      />
                      <label>{{ $t("global.creationdatefrom") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        id="date-erstellt-bis"
                        date-format="dd.mm.yy"
                        showIcon
                        :initial="filters.erstelldatumBis"
                        field="erstelldatumBis"
                        @updatefull="setFilterDate"
                      />
                      <label>{{ $t("global.creationdateuntil") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group" id="number-of-records">
                      <input
                        v-model="filters.count"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.numberofrecords") }}</label>
                    </div>
                  </div>
                </div>
                <filter-search-reset-button/>
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.documentslist')"
              :anzahl="anzahlDaten"
              :anzahlAusFilter="anzahlDatenFilter"
            ></head-menu>
            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row d-flex justify-content-between">
                  <div class="ml-4"></div>
                  <div class="mr-4 float-right">
                    <button
                      class="btn btn-danger"
                      @click="oeffneLoeschenModal"
                      v-if="berechtigungen.m_korrespondenz.delete"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-trash"
                        class="mr-2"
                      />{{ $t("global.delete") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row block belegliste">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="listData"
                    :fields="fields"
                    :sort-compare="sortDates"
                    sort-icon-left
                    fixed
                    selectable
                    select-mode="range"
                    @row-dblclicked="details"
                  >
                    <template slot="top-row" slot-scope="{ fields }">
                      <td v-for="field in fields" :key="field.key">
                        <b-input
                          v-if="
                            field.key != 'selected' && field.searchable != false
                          "
                          size="sm"
                          v-model="filters[field.key]"
                          :placeholder="field.label"
                        />
                      </td>
                    </template>

                    <template v-slot:head(selected)="header">
                      <b-form-checkbox
                        ref="headerCheckbox"
                        v-model="header.selected"
                        @input="selectAllRows(header)"
                      ></b-form-checkbox>
                    </template>

                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>
                  </b-table>
                  <infinite-loading
                    :identifier="infiniteId"
                    @infinite="infiniteHandler"
                  >
                    <div slot="spinner">{{ $t("global.loadmoredata") }}</div>
                    <div slot="no-more">{{ $t("global.nofurtherdata") }}</div>
                    <div slot="no-results">{{ $t("global.nofurtherdatafound") }}</div>
                  </infinite-loading>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <loeschen-modal
      id="dokumente-loeschen-modal"
      @confirmed="loeschen"
      :multiple="true"
    />

    <messagebox-count
      :headerText="$t('global.documentsdeleted')"
      id="count-delete-alert-msgbox"
      :ok="true"
      :cancel="false"
      :okText="$t('global.ok')"
      :cancelText="$t('global.cancel')"
    />
    <loading-overlay v-show="loading"></loading-overlay>
  </div>
</template>

<script>
import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";

import store from "@/store";
import page from "@/mixins/Page";
import dashboard from "@/mixins/Dashboard";
import filter from "@/mixins/Filter";

import LoeschenModal from "@/components/Modals/LoeschenModal";

import DatePicker from "@/components/Datepicker";
import FilterSearchResetButton from "@/components/Buttons/FilterSearchResetButton";

export default {
  name: "Dokumentenliste",
  components: {
    Navbar,
    HeadMenu,
    HeadMenuFilter,
    DatePicker,
    LoeschenModal,
    FilterSearchResetButton,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return "BX: " + this.$t("global.documents");
      },
    };
  },
  mixins: [page, dashboard, filter],
  props: {},
  store,
  data() {
    return {
      fields: [
        { key: "selected", label: "" },
        {
          key: "person",
          searchable: false,
          sortable: true,
          label: this.$t("global.person"),
          formatter: (value, key, item) => {
            let returnValue = "";

            if (item.person) {
              returnValue = item.person.personName;
            } else {
              returnValue = item.lead
                ? item.lead.ansprechpartner.personName
                : "";
            }

            return returnValue;
          },
        },
        {
          key: "id",
          searchable: false,
          sortable: true,
          label: this.$t("global.link"),
          formatter: (value, key, item) => {
            let returnValue = "";

            if (item.lead) {
              returnValue = "Lead - " + item.lead.bezeichnung;
            } else {
              returnValue = "Person";
            }

            return returnValue;
          },
        },
        { key: "erstellt", label: this.$t("global.created"), searchable: false },
        { key: "ersteller.personName", label: this.$t("global.creator") },
        { key: "bezeichnung", sortable: true, label: this.$t("global.designation") },
      ],
    };
  },
  computed: {
    queryParams: function () {
      var params = {}

      params.filterName = this.setQueryParam(this.filters.name);
      params.filterVorname = this.setQueryParam(this.filters.vorname);
      params.filterBezeichnung = this.setQueryParam(this.filters.bezeichnung);
      params.filterErsteller = this.setQueryParam(
        this.filters["ersteller.personName"]
      );
      params.filterCount = this.setQueryParam(this.filters.count);

      if (this.filters.erstelldatumVon && this.filters.erstelldatumVon != "..")
        params.filterErstelldatumVon = this.filters.erstelldatumVon;

      if (this.filters.erstelldatumBis && this.filters.erstelldatumBis != "..")
        params.filterErstelldatumBis = this.filters.erstelldatumBis;

      return params;
    },
  },
  created() {
    this.initFilter("dokumente-liste", "dokument/", true);
  },
  mounted() {
    this.$bus.$emit("searchWithFilter");
    this.setHeaders("global", [
      "",
      "person",
      "link",
      "created",
      "creator",
      "designation",
    ]);
  },
  methods: {
    details(dokumente) {
      this.$router.push({
        path: "/ressourcen/dokument/" + dokumente.id,
      });
    },

    loeschen() {
      this.delete("dokument/", this.selectedIds);
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit("openLoeschenModal", "dokumente-loeschen-modal");
      else {
        this.$notify({
          type: "warn",
          title: this.$t("notification.actionfailed"),
          text: this.$t("notification.noitemselected"),
        });
      }
    },
  },
};
</script>

<style>
</style>
